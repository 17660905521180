<template>
  <v-container grid-list-md pa-0 relative>
    <v-overlay :value="sendingSms">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-layout row wrap>
      <v-flex xs12 sm12>
        <v-card class="primary--border student_list" outlined>
          <v-card-title class="title">Behaviour Summary Reports
            <v-spacer></v-spacer>
            <v-btn
              v-if="selectedStudents.length > 0"
              color="primary"
              small
              outlined
              @click="confirmDialog=true"
            >
              <v-icon class="mr-1" small>message</v-icon> Send SMS ({{ selectedStudents.length }})
            </v-btn>
            &nbsp;
            <v-download-column-select v-if="($auth.isAdmin() ||
                $auth.loggedInRole().slug === 'administrator') &&
              form.items.data.length > 0
              " :showPDF="false" :downloadColumns="dynamicColumns" @onDownload="downloadRecord">
              DOWNLOAD BEHAVIOUR SUMMARY RECORD
            </v-download-column-select>
          </v-card-title>

          <v-scroll-y-transition>
            <v-card outlined>
              <v-card-title class="pb-0 mb-0">
                <v-flex xs2>
                  <v-student-search-field @onClear="onClear" @onSelectStudent="selectStudent"></v-student-search-field>
                </v-flex>
                <v-flex xs2>
                  <!-- <v-grade-field

                          :disabled="enrollCode ? true :false"
                          @selectedGrade="getSections()"
                          v-model="gradeId"
                          :multiple="true"
                          >
                      </v-grade-field> -->
                  <v-select :disabled="enrollCode ? true :false" :items="grades" v-model="gradeId" label="Select Grades" multiple class="pt-0" outlined dense @change="getSections(),onGradeSelect()">
                    <v-list-item slot="prepend-item" ripple @click="toggle">
                      <v-list-item-action>
                        <v-icon :color="gradeId.length > 0 ? 'indigo darken-4' : ''">
                          {{ icon }}
                        </v-icon>
                      </v-list-item-action>
                      <v-list-item-title>Select All</v-list-item-title>
                    </v-list-item>
                    <v-divider slot="prepend-item" class="mt-2"></v-divider>
                    <v-divider slot="append-item" class="mb-2"></v-divider>
                  </v-select>
                </v-flex>
                <v-flex xs2>
                  <v-select :loading="loadingSection" :disabled="sections.length < 1" :items="sections" class="pa-0"
                    label="Select section" outlined dense v-model="sectionId" />
                </v-flex>
                <v-flex xs3>
                    <v-subject-field
                          v-model="subjectId"
                          :gradeId="gradeId"
                          :subjects="subjects"
                        ></v-subject-field>
                </v-flex>
                <v-flex xs1>
                <v-btn
                  @click="moreFilterDialogue = true"
                  color="primary"
                  style="margin-top: -20px; float: right"
                  elevation="2"
                  icon
                  ><v-icon>filter_alt</v-icon></v-btn
                >
              </v-flex>

                <!-- <v-flex xs2>
                      <v-subject-field
                            v-model="subjectId"
                            :gradeId="gradeId"
                            :subjects="subjects"
                          ></v-subject-field>
                  </v-flex> -->
                <!-- <v-flex xs3>
                      <v-calendar-field
                          id-val="from_date"
                          v-model="submitDate"
                          label="Submitted Date"
                          >
                      </v-calendar-field>
                  </v-flex> -->
                
                <v-flex xs1>
                  <search-button :permission="true" @action="get()" :disabled="(this.gradeId || this.sectionId || this.enrollCode || this.frmDate || this.toDate) ? false:true">
                  </search-button>
                </v-flex>
                <v-flex xs1>
                  <transition name="animate-css-transition" enter-active-class="animated fadeInRight"
                    leave-active-class="animated fadeOutRight">
                    <v-btn v-if="frmDate || toDate || gradeId || enrollCode" id="staff_clear_filter" outlined color="primary"
                      text class="mt-n7-2 staff_clear_filter"
                      @click="frmDate = '', toDate = '', gradeId = '', enrollCode = '', enrollId = '', sectionId = '', sections = [], get()">
                      Clear
                    </v-btn>
                  </transition>
                </v-flex>

              </v-card-title>
            </v-card>
          </v-scroll-y-transition>
          <v-data-table :headers="headers" :items="form.items.data" :options.sync="pagination" :footer-props="footerProps"
            :server-items-length="form.items.meta.total" :loading="form.loading">
            <template v-slot:header.select>
              <v-checkbox
                v-if="form.items.data.length > 0"
                class="ml-0"
                primary
                :input-value="
                  form.items.data.length === selectedStudents.length &&
                  selectedStudents.length !== 0
                "
                :indeterminate="
                  selectedStudents.length !== 0 &&
                  selectedStudents.length < form.items.data.length
                "
                @click.stop="toggleAllStudent"
                hide-details
              ></v-checkbox>
              <span v-else>##</span>
            </template>
            <template v-slot:item="{ index, item }">
              <tr>
                <td>
                  <v-checkbox
                    :input-value="selectedStudents.includes(item)"
                    primary
                    hide-details
                    @click.stop="selectStudent(item)"
                  >
                  </v-checkbox>
                </td>
                <td align="left">{{ index + form.items.meta.from }}</td>
                <td class="text-xs-left">
                  {{ item.name }}
                  <div v-if="item.enroll_code">
                    <span style="font-size: 10px; color: #666">(Enroll Code: {{ item.enroll_code }})</span>
                  </div>
                </td>
                <td class="text-xs-left">
                  {{ item.grade }}
                  <div v-if="item.section">
                    <span style="font-size: 10px; color: #666">(Section: {{ item.section }})</span>
                  </div>
                </td>
                <td class="text-xs-right"
                  @click="refreshKey = refreshKey + 1, status = 'all', enroll_code_behaviour_list = item.enroll_code, student_name = item.name, displayDialog = true">

                  {{ item.total_count }}

                  <v-icon style="color: black;" @click="refreshKey = refreshKey++">remove_red_eye</v-icon>

                  <div v-if="item.total_count">

                    <span style="font-size: 10px; color: #666">(Total % : 100)</span>

                  </div>

                </td>
                <td class="text-xs-right"
                  @click="refreshKey = refreshKey + 1, status = 'positive', enroll_code_behaviour_list = item.enroll_code, student_name = item.name, displayDialog = true">
                  {{ item.positive_count }}

                  <!-- <v-icon style="color: green;">remove_red_eye</v-icon> -->
                  <v-icon style="color: green;" @click="refreshKey = refreshKey++">remove_red_eye</v-icon>

                  <div v-if="item.positive_count">

                    <span style="font-size: 10px; color: #666">(Positive % : {{ item.positive_per }})</span>
                  </div>
                </td>
                <td class="text-xs-right"
                  @click="refreshKey = refreshKey + 1, status = 'negative', enroll_code_behaviour_list = item.enroll_code, student_name = item.name, displayDialog = true">
                  {{ item.negative_count }}
                  <v-icon style="color: red;" @click="refreshKey = refreshKey + 1">remove_red_eye</v-icon>
                  <div v-if="item.positive_count">
                    <span style="font-size: 10px; color: #666">(Negative % : {{ item.negative_per }})</span>
                  </div>
                </td>
                <td class="text-xs-left">
                  {{ item.guardian_name }}
                  <div v-if="item.mobile && item.mobile !== 'null'">
                    <span style="font-size: 10px; color: #666">(Mobile: {{ item.mobile }})</span>
                  </div>
                </td>
                <td class="text-right">
                  <v-btn
                    color="primary"
                    small
                    outlined
                    @click="singleSmsData(item,index)"
                    :loading="item.loading  ? item.loading : false"
                    >
                    <v-icon class="mr-1" small>message</v-icon> Send SMS 
                  </v-btn>
                 
                </td>
              </tr>
            </template>

          </v-data-table>
        </v-card>
      </v-flex>
      <v-dialog v-model="displayDialog" persistent max-width="1250px">
        <v-card>
          <v-card-title class="primary white--text">
            <span class="title">Behaviour List of {{ student_name }} ({{ enroll_code_behaviour_list }})</span>
          </v-card-title>
          <behaviourReport :reuseComponent="true" :group_type_status="status" :key="refreshKey"
            :enroll_code_comp="enroll_code_behaviour_list" :from_date="frmDate" :to_date="toDate" :grade_id="gradeId" :section_id="sectionId"></behaviourReport>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="warning" text
              @click="(displayDialog = false), status = '', enroll_code_behaviour_list = '', student_name = ''">Close</v-btn>
          </v-card-actions>
        </v-card>

      </v-dialog>
      <v-dialog v-model="moreFilterDialogue" persistent max-width="550">
            <v-card>
              <v-card-title class="title pa-3 primary white--text">
                <v-icon class="mr-2 white--text">filter_alt</v-icon>
                More Filters
              </v-card-title>
              <v-card-title class="title pa-3">
                <v-flex xs12>
                  <v-user-search-field
                  @onSelectUser="searchUser"
                ></v-user-search-field>
                </v-flex>
                <v-flex mt-2 xs12>
                  <v-calendar-field
                    id-val="from_date"
                    v-model="frmDate"
                    label="From Date"
                  >
                  </v-calendar-field>
                </v-flex>
                <br>
                <v-flex mt-5 xs12>
                    <v-calendar-field
                    id-val="to_date"
                    v-model="toDate"
                    label="To Date"
                  >
                  </v-calendar-field>
                </v-flex>
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="warning"
                  outlined
                  small
                  @click="moreFilterDialogue = false"
                  >Close</v-btn
                >
                <v-btn color="success" @click="get()" small>
                  Filter Now
                </v-btn>
              </v-card-actions>
            </v-card>
      </v-dialog>
    </v-layout>
    <v-dialog v-model="confirmDialog" persistent max-width="290">
        <v-card>
          <v-card-title class="title pa-3 warning white--text">
            <v-icon class="mr-2 white--text">warning</v-icon>
            Please, wait!
          </v-card-title>
          <v-card-text class="ma-0">Are you sure yo want to send Sms</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="success" outlined small @click="confirmDialog = false"
              >No</v-btn
            >
            <v-btn
              color="warning"
              outlined
              small
              @click="sendSms"
              >Yes</v-btn
            >
           
          </v-card-actions>
        </v-card>
      </v-dialog>
  </v-container>
</template>
  
<script>
import { mapState } from "vuex";

import Form from "@/library/Form";

import Mixins from "@/library/Mixins";
import { redirectDownloadUrl } from "../../../../library/helpers";
import behaviourReport from "./index.vue"
import { toDate } from "date-fns";
const NepaliDate = require("nepali-date-js");
const nd = new NepaliDate();
export default {
  mixins: [Mixins],
  components: { behaviourReport },

  data: () => ({
    moreFilterDialogue:false,
   
    refreshKey: 0,
    sectionId: '',
    displayDialog: false,
    form: new Form({}, '/api/behaviour-summary-report'),
    // behaviourform:new Form({},'/api/behaviour-list'),
    enrollId: '',
    gradeId: '',
    enrollCode: '',
    submitDate: '',
    frmDate: '',
    toDate: '',
    headers: [
			{ text: '', align: 'left', value: 'select', width: 3, sortable: false },

      { text: "#", align: "left", value: "id", sortable: false },
      {
        text: "Student Name",
        align: "left",
        value: "name",
      },
      {
        text: "Grade(Section)",
        align: "left",
        value: "grade_section",
        sortable: false,
      },
      {
        text: "Total no. of Behaviour",
        align: "left",
        value: "star",
        sortable: false,

      },
      {
        text: "No. of Positive Behaviour",
        align: "left",
        value: "positive_count",
      },
      {
        text: "No .of Negative Behaviour",
        align: "left",
        value: "negative_count",
      },
      {
        text: "Guardian Name",
        align: "left",
        value: "date_time",
        sortable: false,
      },
        { text: "Action", align: "right", value:'action', sortable: false },
    ],

    downloadColumns: [
      {
        value: "name",
        name: "Name",
        selected: false,
      },
      {
        value: "enroll_code",
        name: "Enroll Code",
        selected: false,
      },
      {
        value: "grade_section",
        name: "Grade / Section",
        selected: false,
      },
      {
        value: "guardian_name",
        name: "Guardian Name",
        selected: false,
      },
      {
        value: "mobile",
        name: "Mobile",
        selected: false,
      },
      {
        value: "total_behaviour",
        name: "Total No. of Behaviour",
        selected: false,
      },
      {
        value: "total_positive",
        name: "Total No. Positive Behaviour",
        selected: false,
      },
      {
        value: "total_positive_per",
        name: "Total No. Positive Behaviour in %",
        selected: false,
      },
      {
        value: "total_negative",
        name: "Total No. Negative Behaviour",
        selected: false,
      },
      {
        value: "total_negative_per",
        name: "Total No. Negative Behaviour in %",
        selected: false,
      },



    ],
    enroll_code_behaviour_list: '',
    status: '',
    student_name: '',
    sections: [],
    loadingSection: false,
    grades: [],
    submitted_by:'',
    subjectId:'',
    subjects:[],
    selectedEnrollCodes: [],
    selectedStudents: [],
    sendingSms: false,
    confirmDialog: false,

    singleSmsSendData: '',
    smsIndex:'',
  }),
  watch: {
    pagination: function () {
      if (this.gradeId || this.sectionId || this.enrollCode || this.frmDate || this.toDate || this.subjectId || this.submitted_by) this.get();
    },
  },
  mounted() {
    this.getGrades();
  },

  computed: {
    dynamicColumns() {
      let columns = [];
      this.downloadColumns.map((item) => {
        if (item.toAdmin === true && this.$auth.isAdmin()) {
          columns.push(item);
        } else if (!item.toAdmin) {
          columns.push(item);
        }
      });
      return columns;
    },
    ...mapState(['batch']),
    selectAllGrades() {
      return this.gradeId.length === this.grades.length;
    },
    selectFewGrades() {
      return this.gradeId.length > 0 && !this.selectAllGrades;
    },
    icon() {
      if (this.selectAllGrades) return "check_box";
      if (this.selectFewGrades) return "indeterminate_check_box";
      return "add_box";
    },
  },


  methods: {
    get(params) {
      if (this.gradeId || this.sectionId || this.enrollCode || this.frmDate || this.toDate || this.submitted_by || this.subjectId) {
        let extraParams = "&enroll_id=" + this.enrollId + "&grade_id=" + this.gradeId + "&section_id=" + this.sectionId + "&enroll_code=" + this.enrollCode + "&from_date=" + this.frmDate + "&to_date=" + this.toDate+"&submitted_by="+this.submitted_by+"&subject_id="
                            +this.subjectId
        let query = [null, undefined].includes(params) ? this.queryString(extraParams) : params;
        this.form.get(null, query).then(({ data }) => {
        });
      }
    },

    selectStudent(student) {
      if (student) {
        this.enrollId = student.enroll_id;
        this.enrollCode = student.enroll_code;
        this.gradeId = '';

        this.get();
      }
    },

    onGradeSelect() {
      if(this.gradeId.length ==1){
        this.subjectId = ''
        this.subjects = []
        this.getSubjects()
      }else{
        this.subjectId = ''
        this.subjects = []
      }
    },
    getSubjects() {
        this.$rest.get("/api/subject?grade=" + this.gradeId).then(({ data }) => {
          this.subjects = data.data.map((item) => {
              return { id: item.id, text: item.name };
          });
        });
    },
    // getBehaviourList(params){
    //   this.displayDialog = true;
    //   let extraParams = "status="+this.status+"&enroll_code="+this.enroll_code_behaviour_list;
    //   let query = [null, undefined].includes(params) ? this.queryString(extraParams) : params;
    //   this.behaviourform.get(null, query);


    // },
    downloadRecord({ type, columns, columnString }) {
      redirectDownloadUrl({
        uri: "/download/academic/student-behaviour-summary-report",
        queryString: `columns=${columnString}&enroll_id=${this.enrollId}&grade_id=${this.gradeId}&section_id=${this.sectionId}&enroll_code=${this.enrollCode}&from_date=${this.frmDate}&to_date=${this.toDate}&submitted_by=${this.submitted_by}&subject_id=${this.subjectId}`,
      });
    },
    onClear() {
      this.enrollCode = "";
      this.get();
    },
    getSections() {
      if (this.gradeId.length == 1) {
        this.sectionId = '';
        this.sections = [];
        let grade_id = this.gradeId[0];
        this.loadingSection = true;
        this.$rest.get('/api/section?batch=' + this.batch.id + '&grade=' + grade_id).then(({ data }) => {
          this.sections = data.data.map(item => {
            return { value: item.id, text: item.name }
          });
        }).finally(() => {
          this.loadingSection = false;
        })
      } else {
        this.sectionId = ''
        this.sections = []
      }
    },
    getGrades() {
      this.$rest
        .get(
          "/api/grades?rowsPerPage=25&sortBy=rank&descending=false&roleWise=true&slim=true"
        )
        .then(({ data }) => {
          this.grades = data.data.map((item) => {
            return { value: item.id, text: item.name };
          });
        });
    },
    toggle() {
      this.$nextTick(() => {
        if (this.selectAllGrades) {
          this.gradeId = [];
        } else {
          this.gradeId = this.grades.map((grade) => {
            return grade.value;
          });
        }
      });
    },
    searchUser(user) {
      this.submitted_by = user.id;
    },
    
    toggleAllStudent() {
      if (
        this.selectedStudents.length > 0 &&
        this.selectedStudents.length < this.form.items.data.length
      ) {
        this.selectedStudents = this.form.items.data;
      } else if (
        this.selectedStudents.length > 0 &&
        this.selectedStudents.length === this.form.items.data.length
      ) {
        this.selectedStudents = [];
      } else {
        this.selectedStudents = [];
        this.form.items.data.map((item) => {
          this.selectedStudents.push(item);
        });
      }
    },

    selectStudent(item) {
      if (item)
        if (this.selectedStudents.indexOf(item) > -1)
          this.selectedStudents.splice(this.selectedStudents.indexOf(item), 1);
        else this.selectedStudents.push(item);
    },

    singleSmsData(item, index){
      this.singleSmsSendData = item;
      this.smsIndex = index;
      this.confirmDialog = true;
    },
    sendSms(){
      let item = this.singleSmsSendData;
      let index = this.smsIndex;
      this.confirmDialog = false;
      if(item || index) {
        this.selectedEnrollCodes.push(item.enroll_code);
        this.form.items.data[index].loading = true;
        this.sendingSms = false
      }else{
        const enrollCodes = this.selectedStudents.map(student => student.enroll_code);
        this.selectedEnrollCodes.push(enrollCodes)
        this.sendingSms = true

      }
      this.$rest.post( "/api/send-behaviour-sms",{
          gradeId: this.gradeId[0],
          subjectId: this.subjectId,
          enrollCode: this.selectedEnrollCodes.join(','),
          sectionId: this.sectionId,
          fromDate: this.frmDate,
          toDate: this.toDate
      })
      .then((res) => {
        this.$events.fire("notification", {
          message: "SMS sent successfully",
          status: "success",
        });
        // this.selectedEnrollCodes=[];
        // this.selectedStudents = [];
        this.sendingSms = false
        this.resetSmsData();
        this.form.items.data[index].loading = false;

        

      }).catch((e) => {
        this.$events.fire("notification", {
          message: "something went wrong",
          status: "error",
        });
        // this.sendingSms = false
        this.resetSmsData();
        this.form.items.data[index].loading = false;



      }).finally(() => {
        this.resetSmsData();
        if(index){
          this.form.items.data[index].loading = false;
            
        }

      })
    },
    resetSmsData(){
      this.sendingSms = false;
      this.selectedEnrollCodes=[];
      this.selectedStudents = [];
      this.singleSmsSendData = ''
      this.smsIndex = ''
    }
  },

  
};
</script>
  